// src/pages/Home.js

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

function Home() {
  return (
    <div className="bg-white text-gray-800">
      <Helmet>
        <title>Copelnix - ホーム</title>
        <meta
          name="description"
          content="Copelnix株式会社は、最新のAI技術を活用したソリューションを提供します。"
        />
      </Helmet>

      {/* ヒーローセクション */}
      <section
        className="relative bg-cover bg-center h-screen flex items-center justify-center"
        style={{ backgroundImage: "url('/images/hero-background.jpg')" }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-blue-800 to-transparent opacity-75"></div>
        <div className="relative text-center px-4 text-white">
          <h1 className="text-5xl font-extrabold mb-4">AIで未来を創造する</h1>
          <p className="text-xl mb-8">
            Copelnixは先進的なAIソリューションでビジネスを加速します。
          </p>
          <Link to="/services">
            <button className="bg-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300">
              サービスを見る
            </button>
          </Link>
        </div>
      </section>

      {/* サービスセクション */}
      <section className="container mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-center mb-12">サービス</h2>
        <p className="text-center mb-8">
          最新のAI技術を活用したソリューションで、ビジネスの生産性を高めます。
        </p>
        <div className="grid md:grid-cols-3 gap-8">
          {/* サービスカード */}
          <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition duration-300 text-center">
            <ChatBubbleLeftEllipsisIcon className="h-12 w-12 text-blue-600 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold mb-2">Chat Tree</h3>
            <p className="mb-4">
              AIを活用した高度なチャットボットソリューション。
            </p>
            <Link
              to="/services/chat-tree"
              className="text-blue-600 hover:underline font-semibold"
            >
              詳しく見る
            </Link>
          </div>
          {/* 他のサービスカード */}
          {/* サービスカードの複製と編集 */}
        </div>
        <div className="text-center mt-8">
          <Link
            to="/services"
            className="text-blue-600 hover:underline font-semibold"
          >
            サービス一覧を見る
          </Link>
        </div>
      </section>

      {/* 料金プランセクション */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">料金プラン</h2>
          <p className="mb-8">
            ビジネスのニーズに合わせた柔軟な料金プランをご用意しています。
          </p>
          <Link to="/pricing">
            <button className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300">
              料金プランを見る
            </button>
          </Link>
        </div>
      </section>

      {/* 会社概要セクション */}
      <section className="container mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-center mb-12">会社概要</h2>
        <p className="text-center mb-8">
          Copelnix株式会社は、最新のAI技術を活用したソリューションを提供します。
        </p>
        <div className="text-center">
          <Link to="/about">
            <button className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300">
              もっと知る
            </button>
          </Link>
        </div>
      </section>

      {/* ニュースセクション */}
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">最新情報</h2>
          {/* ニュースアイテム */}
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow hover:shadow-xl transition duration-300">
              <h3 className="text-2xl font-semibold mb-2">
                新サービスリリースのお知らせ
              </h3>
              <p className="mb-4">2023年10月1日</p>
              <p>新しいAIチャットボット「Chat Tree」をリリースしました。</p>
            </div>
            {/* 他のニュースアイテム */}
          </div>
        </div>
      </section>

      {/* お問い合わせセクション */}
      <section className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">お問い合わせ</h2>
          <p className="mb-8">
            サービスに関するご質問やご相談は、お気軽にお問い合わせください。
          </p>
          <Link to="/contact">
            <button className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition duration-300">
              お問い合わせページへ
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Home;
