// src/pages/News.js

import React from "react";

function News() {
  return (
    <div className="bg-white text-gray-800 min-h-screen p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">ニュース</h1>
      {/* ニュース記事をここに追加します */}
      <div className="space-y-6">
        <div className="bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-2">
            新サービスリリースのお知らせ
          </h2>
          <p className="text-gray-600 mb-2">2024年10月1日</p>
          <p>
            新しいAIチャットボット「Chat
            Tree」をリリースしました。詳細はサービスページをご覧ください。
          </p>
        </div>
        {/* 他のニュース記事を追加 */}
      </div>
    </div>
  );
}

export default News;
