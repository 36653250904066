// src/pages/Services.js
import React from "react";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

function Services() {
  const services = [
    {
      title: "Chat Tree",
      description: "AIを活用した高度なチャットボットソリューション。",
      icon: (
        <ChatBubbleLeftEllipsisIcon className="h-12 w-12 text-blue-600 mb-4" />
      ),
      link: "https://chat-tree.com", // 外部リンクに変更
    },
    // 他のサービスを追加する場合は、ここにオブジェクトを追加します
  ];

  return (
    <div className="bg-white text-gray-800 min-h-screen py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center">サービス一覧</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <div
              key={service.title}
              className="bg-gray-50 p-8 rounded-lg shadow hover:shadow-lg transition duration-300"
            >
              <div className="flex items-center justify-center mb-4">
                {service.icon}
              </div>
              <h2 className="text-2xl font-semibold mb-2 text-center">
                {service.title}
              </h2>
              <p className="mb-6 text-center">{service.description}</p>
              <div className="text-center">
                <a
                  href={service.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
                >
                  詳細を見る
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
