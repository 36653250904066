// src/components/Navbar.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import CopelnixLogo from "../assets/Copelnix.png"; // パスを適宜調整してください

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { name: "会社概要", link: "/about" },
    { name: "サービス", link: "/services" },
    { name: "ニュース", link: "/news" }, // 「料金プラン」を「ニュース」に変更
    { name: "採用情報", link: "/recruit" },
    { name: "お問い合わせ", link: "/contact" },
  ];

  return (
    <nav className="fixed w-full bg-white shadow z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center text-2xl font-bold">
          <img src={CopelnixLogo} alt="Copelnixロゴ" className="h-8 w-8 mr-2" />
          Copelnix株式会社
        </Link>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-800 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 8h16M4 16h16"
                />
              )}
            </svg>
          </button>
        </div>
        <div className={`md:flex items-center ${isOpen ? "block" : "hidden"}`}>
          {menuItems.map((item) => (
            <Link
              key={item.name}
              to={item.link}
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 text-gray-800 hover:text-blue-600"
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
