// src/pages/About.js
import React from "react";

function About() {
  return (
    <div className="bg-white text-gray-800 min-h-screen">
      <div className="container mx-auto py-16 px-4">
        <h1 className="text-4xl font-bold mb-8 text-center">会社概要</h1>

        {/* 会社概要セクション */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">会社情報</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <dl>
                <dt className="font-bold">会社名</dt>
                <dd className="mb-4">
                  Copelnix株式会社（英文：Copelnix Inc.）
                </dd>

                <dt className="font-bold">設立</dt>
                <dd className="mb-4">2024年10月31日</dd>

                <dt className="font-bold">代表者</dt>
                <dd className="mb-4">鈴木 爽太郎</dd>

                <dt className="font-bold">資本金</dt>
                <dd className="mb-4">1,000,000円</dd>
              </dl>
            </div>
            <div>
              <dl>
                <dt className="font-bold">所在地</dt>
                <dd className="mb-4">
                  〒116-0014
                  <br />
                  東京都荒川区東日暮里5-16-1
                </dd>

                <dt className="font-bold">事業内容</dt>
                <dd className="mb-4">
                  <ul className="list-disc list-inside">
                    <li>AIチャットボット「Chat Tree」の開発・運営</li>
                    <li>その他AI関連サービスの提供</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
        </section>

        {/* Copelnixとはセクション */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Copelnixとは</h2>
          <p className="mb-6">
            Copelnix（コペルニクス）は、コペルニクス的転回で有名な天文学者コペルニクスに由来しています。私たちは、既存の常識を根本から変え、全く新しい常識を見つけ出すことを目指しています。
          </p>
          <p>
            最先端のAI技術を活用し、ビジネスや社会に革新的なソリューションを提供することで、人々の生活を豊かにし、新たな価値を創造します。
          </p>
        </section>

        {/* Mission＆Valueセクション */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-8 text-center">
            Mission＆Value
          </h2>
          <h3 className="text-xl font-bold mb-6 text-center">
            <span className="block">To Revolutionize the Common Sense</span>
            <span className="block text-gray-600">常識を革命する</span>
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <h4 className="text-lg font-semibold mb-2">Innovation</h4>
              <p>常に革新的な技術とアイデアで、新しい価値を創造します。</p>
            </div>
            <div className="text-center">
              <h4 className="text-lg font-semibold mb-2">Collaboration</h4>
              <p>
                多様な才能と背景を持つチームで協力し、最高の成果を追求します。
              </p>
            </div>
            <div className="text-center">
              <h4 className="text-lg font-semibold mb-2">Integrity</h4>
              <p>
                高い倫理観と責任感を持って、信頼されるサービスを提供します。
              </p>
            </div>
          </div>
        </section>

        {/* お問い合わせセクション */}
        <section className="text-center">
          <h2 className="text-2xl font-semibold mb-4">お問い合わせ</h2>
          <p className="mb-6">
            Copelnixへのお問い合わせは、以下のボタンからお気軽にご連絡ください。
          </p>
          <a
            href="/contact"
            className="inline-block bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
          >
            お問い合わせページへ
          </a>
        </section>
      </div>
    </div>
  );
}

export default About;
